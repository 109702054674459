@import '~@angular/cdk/overlay-prebuilt.css';

body.active {
    overflow: visible!important;
}

.btn{
    transform: none!important;
  &_disabled{
    opacity: .5;
    cursor: not-allowed !important;
    &:hover{
      transform: none !important;
    }
  }
}

html {
  /*background: #d7e3f6;*/
}
.delete-slide{
  position: absolute;
  top: 2rem;
  right: 2rem;
  z-index: 50;

  button{
    margin: 0;
  }
}

.slide .section__delete{
  z-index: 200;
}

@-webkit-keyframes pulse {
  50% {
    background: white;
  }
}
@keyframes pulse {
  50% {
    background: white;
  }
}

.btn-action_close{
  position: relative;
  z-index: 200;
}

.slide_add{
  cursor: pointer;
}

#preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2000;

  display: flex;
  align-items: center;
  justify-content: center;

  & > .spinner {
    align-self: flex-end;
    margin-bottom: 15px;

    content: '';
    position: relative;

    //top: 90%;
    //left: 50%;

    width: 6px;
    height: 24px;
    //margin: -3px 0 0 -12px;
    -webkit-animation: pulse 750ms infinite;
    animation: pulse 750ms infinite;
    -webkit-animation-delay: 250ms;
    animation-delay: 250ms;

    &:before, &:after {
      content: '';
      position: absolute;
      display: block;
      height: 16px;
      width: 6px;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      -webkit-animation: pulse 750ms infinite;
      animation: pulse 750ms infinite;
    }

    &:before {
      left: -12px;
    }

    &:after {
      left: 12px;
      -webkit-animation-delay: 500ms;
      animation-delay: 500ms;
    }

  }
}

.preview__image {
  width: 260px !important;
}

.embed{
  padding-bottom: 56.25%;
  position: relative;

  iframe{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }
}

.icon-section{
  &~.section{
    display: none;
  }

  &.open ~ .section{
    display: block;
  }
  &.open ~.section__toggle:before {
    background-image: url(https://static.myskillcamp.com/images/studio/icons/icon_bottom_arrow_dark.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 70%;
  }
}

.popover {
  z-index: 999 !important;
  display: block;
}

h3.popover-header {
  display: none;
}

.event_mode_multiple .event-presentation__img .event__dates, .event_mode_single .event-presentation__img .event__dates{
  width: 100%;
}
.ngx-daterangepicker{
  border: none !important;
  height: auto !important;
  background: none !important;
  .input-section{
    height: auto !important;
    border: none !important;
    .value-txt{
      display: none !important;
    }
    .cal-icon{
      position: relative !important;
      right: auto !important;
      bottom: auto !important;
    }
      .calendar .calendar-container .days .day .day-num {
          width: inherit !important;
      }
  }
}
.event__dates.date_number_many .date+.date:before{
  bottom: -1rem;
  height: auto;
}

.event__dates.date_number_many .date .date__title {
  min-height: 3rem;
}

input[type=time]{
    display: inline-block;
    width: auto;
    box-shadow: none;
}

.modal .modal-library {
  overflow-x: hidden;
}

tag-ripple {
  background: #C1D0E8;
  border-radius: 15px;
}

.minimal tag {
  background: none !important;
}

.media-holder {
  min-width: 100px;
  height: auto;
}

.settings .settings-delete__content .title {
  color: #fff !important;
}

.settings .settings-edit__content .title {
  color: #fff !important;
}


.add-section-content__content .medium-2 {
  width: 20% !important;
  max-height: 176px;
  height: 176px !important;
}

.settings .settings-edit__content.clone button {
  border-radius: 2px;
  display: block;
  padding: 10px;
  font-size: .75rem;
  text-align: center;
  cursor: pointer;
  width: 100%;
  background-color: #fff;
}

.settings .settings-edit__content.clone button:hover {
  background-color: rgba(244,247,251,.3);
  color: #fff;
}

.settings .settings-edit__content.clone button.cancel {
  background-color: rgba(244, 247, 251, .5);
  color: #fff;
}

.folder_display_block .folder__item .actions .delete-miniatures:hover {
  background-color: rgba(244,247,251,.3);
  color: #fff;
}

.settings .settings-edit__content.clone button.cancel:hover {
  background-color: rgba(244,247,251,.3);
}

#library .library-topbar .topbar .dropdown .dropdown-list__choice.re-use-choice {
  padding:.3rem .5rem;
}


.folder_display_list .folder__item.re-use-activity {
  padding: 0.3rem 0;
}

.section-content__checkbox {
  padding-left: 4rem;
  padding-top: 1rem;
}

.checkbox-section-content:before {
  width: 1rem;
  height: 1rem;
  display: inline-block;
  vertical-align: text-top;
  margin-right: 1rem;
  content: url(https://static.myskillcamp.com/images/icon/icon-studio/icon_answer_default_qcm.svg);
}

.checkbox-section-content.selected:before {
  content: url(https://static.myskillcamp.com/images/icon/icon-studio/icon_answer_check_qcm.svg);
}

.library_content .pagination .page-link {
  display: inherit;
  padding: .25rem .5rem;
}

.ngx-daterangepicker[_ngcontent-c11] .input-section[_ngcontent-c11] .cal-icon[_ngcontent-c11] svg[_ngcontent-c11] path[_ngcontent-c11] {
    fill: #fff !important;
}

.ngx-daterangepicker[_ngcontent-c11] .calendar[_ngcontent-c11] .calendar-container[_ngcontent-c11] .days[_ngcontent-c11] .day[_ngcontent-c11] .day-num[_ngcontent-c11] {
    width: inherit !important;
}

.sidebar__container {
    overflow-y: scroll;
}

.m-media-journey, .content-media {
    .embeded iframe {
        height: 100% !important;
    }
}

.box__folder-header {
    z-index: 15 !important;
}

.m-link {
    cursor: pointer;
}

.m-link--white {
    color: white !important;
}

.m-link--blue {
    color: #5b70ff !important;
}

.listing__holder {
    z-index: 0 !important;
}

.media-manager__actions {
    z-index: 0 !important;
}

.course-presentation__img {
    img {
        opacity: 0.6 !important;
    }
}

#custom-app {
    display: none;
}

.m-visibility-hidden {
    visibility: hidden !important;
}

.aside-content {
    .fr-img-caption {
        &.fr-dib {
            margin: 5px auto;
            display: block;
            float: none;
            vertical-align: top;
        }

        &.fr-fil {
            margin-left: 0;
            text-align: left;
        }

        &.fr-fir {
            margin-right: 0;
            text-align: right;
        }

        .fr-img-wrap {
            padding: 0;
            display: inline-block;
            margin: auto;
            text-align: center;
            width: 100%;

            img {
                display: block;
                margin: auto;
                width: 100%;
            }

            .fr-inner {
                margin: auto;
                display: block;
                padding: 5px 5px 10px;
                font-size: 14px;
                font-weight: initial;
                box-sizing: border-box;
                opacity: 0.9;
                width: 100%;
                text-align: center;
            }
        }
    }
}

.generic-disabled {
  filter: grayscale(80%) !important;
  cursor: not-allowed !important;
  background-color: whitesmoke !important;

  a {
    cursor: not-allowed !important;
  }
}

:root {
    --msc-purple: rgb(124, 108, 222);
}
